import { DigitalSignatureEnum } from "app/modules/admin/cedente/interfaces/assignor-form.interface";
import { IAssignor } from "app/modules/admin/cedente/interfaces/assignor.interface";

export interface IResponseAssignorForm {
    assignorData: IAssignor;
    documents?: IDocumentsAssignor[];
    creditAnalysis?: {
        status: CreditAnalysisStatusEnum;
        typeOfAnalysis: CreditAnalysisEnum;
    };
}

export interface ICreditAnalysisAssignor {
    assignorId: number;
    minibankId: number;
    status: CreditAnalysisStatusEnum;
    typeOfAnalysis: CreditAnalysisEnum;
    attachmentId?: number;
    additionalAnalysisCount?: number;
}

export type IUpdateCreditAnalysisAssignor = Partial<ICreditAnalysisAssignor>;

export interface IDocumentsAssignor { 
    typeDocument: ITypeDocument;
    observation: string;
    files: File[];
}

export interface IAssignorBankForm {
    account: string;
    accountDigit: number;
    agency: number;
    bank: string;
    agencyDigit: number;
    pix: string;
    pixKeyType: PixKeyTypeEnum;
}

export interface IAllBrazilianBanks {
    longName: string;
    shortName: string;
    codeBank: string;
}

export interface ITypeDocument {
    id: number;
    abbreviation: string;
    description: string;
}

export enum PixKeyTypeEnum {
    CPF = "CPF",
    CNPJ = "CNPJ",
    EMAIL = "EMAIL",
    PHONE = "PHONE",
    RANDOM_KEY = "RANDOM_KEY",
}

export interface IViewValue {
    value: string;
    viewValue: string;
}

export const pixTypes = [
    { value: PixKeyTypeEnum.CPF, viewValue: "CPF" },
    { value: PixKeyTypeEnum.CNPJ, viewValue: "CNPJ" },
    { value: PixKeyTypeEnum.EMAIL, viewValue: "EMAIL" },
    { value: PixKeyTypeEnum.PHONE, viewValue: "CELULAR" },
    { value: PixKeyTypeEnum.RANDOM_KEY, viewValue: "CHAVE ALEATÓRIA" },
];

export const digitalSignatureTypes = [
    { value: DigitalSignatureEnum.E_CPF, viewValue: "E-CPF" },
    { value: DigitalSignatureEnum.E_CNPJ, viewValue: "E-CNPJ" },
    { value: DigitalSignatureEnum.ELETRONICA, viewValue: "Eletrônica" },
];

export enum CreditAnalysisEnum {
    RISCO_ZERO = "RISCO_ZERO",
    ANALISE_SIMPLES = "ANALISE_SIMPLES",
    ANALISE_COMPLETA = "ANALISE_COMPLETA",
}

export enum CreditAnalysisStatusEnum {
    ANALISE_SOLICITADA = "ANALISE_SOLICITADA",
    ANALISE_DE_CREDITO = "ANALISE_DE_CREDITO",
    AGUARDANDO_APROVACAO = "AGUARDANDO_APROVACAO",
    APROVADO = "APROVADO",
    REPROVADO = "REPROVADO",
}

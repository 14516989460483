import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { AuthService } from "app/core/auth/auth.service";
import { RolesInterface } from "app/modules/admin/usuarios/perfil/perfil.types";
import { FuseNavigationItem } from "../../../@fuse/components/navigation";

@Directive({
    selector: "[claim]",
})
export class RoleDirective implements OnInit {
    @Input() claim: Partial<RolesInterface>;

    constructor(private _elem: ElementRef<HTMLElement>, private _authService: AuthService) {}

    ngOnInit() {
        this.applyPermission();
    }

    applyPermission() {
        if (this.claim) {
            const hasDefined = this._authService.checkPermission(this.claim);
            if (!hasDefined) {
                this._elem.nativeElement.remove();
            }
        }
    }
}

@Directive({
    selector: "[claimGroup]",
})
export class RoleGroupDirective implements OnInit {
    @Input() claimGroup: FuseNavigationItem;

    constructor(private _elem: ElementRef<HTMLElement>, private _authService: AuthService) {}

    ngOnInit() {
        this.applyPermission();
    }

    applyPermission() {
        if (this.claimGroup) {
            const validator = this.claimGroup.children.some((permissao) => {
                return this._authService.checkPermission(permissao.role);
            });
            if (!validator) {
                this._elem.nativeElement.remove();
            }
        }
    }
}

@Directive({
    selector: "[groupClaim]",
})
export class RoleGroupClaimDirective implements OnInit {
    @Input() groupClaim: Array<Partial<RolesInterface>>;

    constructor(private _elem: ElementRef<HTMLElement>, private _authService: AuthService) {}

    ngOnInit() {
        this.applyPermission();
    }

    applyPermission() {
        if (this.groupClaim) {
            const hasAllRoles = [];

            this.groupClaim.forEach((claim) => {
                const hasDefined = this._authService.checkPermission(claim);
                hasAllRoles.push(hasDefined);
            });

            if (!hasAllRoles.some((item) => item)) {
                this._elem.nativeElement.remove();
            }
        }
    }
}

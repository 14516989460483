import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

interface ISnackBarData {
    title: string;
    message: string;
    linkText: string;
    linkUrl: string;
}

@Component({
    selector: "custom-snackbar",
    templateUrl: "./snackbar.component.html",
})
export class CustomSnackbarComponent {

    public title: string;
    public message: string;
    public linkText: string;
    public linkUrl: string;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: ISnackBarData) {
        this.title = data.title;
        this.message = data.message;
        this.linkText = data.linkText;
        this.linkUrl = data.linkUrl;
    }
}

import { Route } from "@angular/router";

import { InitialDataResolver } from "app/app.resolvers";

import { AuthService } from "app/core/auth/auth.service";

import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";

import { LayoutComponent } from "app/layout/layout.component";
import { UploadModalComponent } from "./components/modals/upload-modal/upload-modal.component";
import { DocumentsUploadComponent } from "./components/documents-upload/documents-upload.component";

// @formatter:off
/* eslint-disable max-len */
export const appRoutes: Route[] = [
    // Redirect empty path to '/operacoes'
    { path: "", pathMatch: "full", redirectTo: "operacoes" },

    // Redirect signed in user to the '/operacoes'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: "signed-in-redirect", pathMatch: "full", redirectTo: "operacoes" },

    // Auth routes for guests
    {
        path: "",
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            {
                path: "confirmation-required",
                loadChildren: () =>
                    import("app/modules/auth/confirmation-required/confirmation-required.module").then(
                        (m) => m.AuthConfirmationRequiredModule,
                    ),
            },
            {
                path: "forgot-password",
                loadChildren: () =>
                    import("app/modules/auth/forgot-password/forgot-password.module").then(
                        (m) => m.AuthForgotPasswordModule,
                    ),
            },
            {
                path: "reset-password",
                loadChildren: () =>
                    import("app/modules/auth/reset-password/reset-password.module").then(
                        (m) => m.AuthResetPasswordModule,
                    ),
            },
            {
                path: "sign-in",
                loadChildren: () => import("app/modules/auth/sign-in/sign-in.module").then((m) => m.AuthSignInModule),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: "empty",
        },
        children: [
            {
                path: "sign-out",
                loadChildren: () =>
                    import("app/modules/auth/sign-out/sign-out.module").then((m) => m.AuthSignOutModule),
            },
            {
                path: "unlock-session",
                loadChildren: () =>
                    import("app/modules/auth/unlock-session/unlock-session.module").then(
                        (m) => m.AuthUnlockSessionModule,
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: "",
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: "operacoes",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        OPERACAO: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/operacoes/operacoes.module").then((m) => m.OperacoesModule),
            },
            {
                path: "clientes",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        CEDENTE: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/cedente/cedente.module").then((m) => m.ClientesModule),
            },
            {
                path: "payers",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        SACADO: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/payer/payer.module").then((m) => m.PayerModule),
            },
            {
                path: "titulos",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        NFE: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/titulos/titulos.module").then((m) => m.TitulosModule),
            },
            {
                path: "minibanco",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        MINIBANCO: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/minibanco/minibanco.module").then((m) => m.MinibancoModule),
            },
            {
                path: "empresa",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        EMPRESA: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/empresa/empresa.module").then((m) => m.EmpresaModule),
            },
            {
                path: "debenturista",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        DEBENTURE: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/debenturistas/debenturistas.module").then((m) => m.DebenturistaModule),
            },
            {
                path: "usuario",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        USUARIO: ["READ"],
                    },
                },
                loadChildren: () => import("app/modules/admin/usuarios/usuario.module").then((m) => m.UsuariosModule),
            },
            {
                path: "occupation",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        OCCUPATION: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/occupation/occupation.module").then((m) => m.OccupationModule),
            },
            {
                path: "auditoria",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        AUDITORIA: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/auditoria/auditoria.module").then((m) => m.AuditoriaModule),
            },
            {
                path: "calcula-me",
                canActivate: [AuthService],
                loadChildren: () =>
                    import("app/modules/admin/calcula-me/calcula-me.module").then((m) => m.CalculaMeModule),
            },
            {
                path: "parceiros",
                loadChildren: () =>
                    import("app/modules/admin/parceiros-comerciais/parceiro-comercial.module").then(
                        (m) => m.ParceirosComerciaisModule,
                    ),
            },
            {
                path: "arquivos",
                data: {
                    permissoes: {
                        ARQUIVO: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/titulos/file-manager/file-manager.module").then(
                        (m) => m.FileManagerModule,
                    ),
            },
            {
                path: "perfil",
                canActivate: [AuthService],
                data: {
                    permissoes: {
                        USUARIO: ["READ"],
                    },
                },
                loadChildren: () =>
                    import("app/modules/admin/usuarios/perfil/perfil.module").then((m) => m.PerfilModule),
            },
            {
                path: "vinculos",
                loadChildren: () =>
                    import("app/modules/admin/vinculo-cedente-usuario/vinculo-cedente-usuario.module").then(
                        (m) => m.VinculoCedenteUsuarioModule,
                    ),
            },
        ],
    },

    {
        path: "**",
        loadChildren: () => import("app/modules/error/error-404/error-404.module").then((m) => m.Error404Module),
    },
];

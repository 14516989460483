import { NgModule } from "@angular/core";
import { CustomSnackbarComponent } from "./snackbar.component";
import { StandardButtonModule } from "app/components/standard-button/standard-button.module";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        StandardButtonModule,
    ],
    declarations: [CustomSnackbarComponent],
    exports: [CustomSnackbarComponent],
})
export class CustomSnackbarModule{}

<button
    *ngIf="!disabled"
    mat-flat-button
    (click)="clickAction()"
    (auxclick)="secondaryClickAction($event)"
    class="mat-focus-indicator hover:opacity-90 rounded-full flex flex-row gap-1 p-2 items-center"
    [ngClass]="buttonStyle + ' ' + classCustom"
>
    <mat-icon *ngIf="matIcon" [ngClass]="type !== 'solid' ? 'text-' + buttonColor : 'text-white'">
        {{ matIcon }}
    </mat-icon>
    <mat-icon
        *ngIf="heroIcon"
        [ngClass]="type !== 'solid' ? 'text-' + buttonColor : 'text-white'"
        [svgIcon]="'heroicons_outline:' + heroIcon"
    ></mat-icon>
    <ng-content></ng-content>
    <span
        class="whitespace-nowrap px-2"
        [ngClass]="type === 'outline' ? 'font-semibold' : ''"
        [ngClass]="hideLabel ? 'hidden md:inline-block' : ''"
        *ngIf="label"
    >
        {{ label }}
    </span>
<button>

<button
    *ngIf="disabled"
    disabled
    class="cursor-not-allowed rounded-full flex flex-row gap-1 p-2 tems-center bg-gray-300"
    [ngClass]="' ' + classCustom"
>
    <mat-icon *ngIf="matIcon">{{ matIcon }}</mat-icon>
    <mat-icon *ngIf="heroIcon" [svgIcon]="'heroicons_outline:' + heroIcon"></mat-icon>
    <span
        *ngIf="label" class="whitespace-nowrap px-2"
        [ngClass]="hideLabel ? 'hidden md:inline-block' : ''"
    >{{ label }}</span>
</button>

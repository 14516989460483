<!-- Button -->
<div class="flex items-center justify-center">
    <p class="text-primary uppercase">
        {{ user?.user?.cargo || "Adimistrador" }}
    </p>
    <div class="h-full border-r w-px mx-2" style="border-color: rgba(221, 227, 233, 0.5)">&nbsp;</div>
    <p [matMenuTriggerFor]="userActions" style="color: #dde3e9" class="cursor-pointer">
        {{ user?.user?.firstName }} {{ user?.user?.lastName || "" }}
    </p>
    <!-- <div
    class="flex items-center justify-center w-10 h-10 rounded-full bg-gray-800 cursor-pointer"
    [matMenuTriggerFor]="userActions"
  >
    <p class="font-bold text-lg" style="color: #dde3e9">
      {{ user?.user?.firstName?.[0]+user?.user?.lastName?.[0] || user?.user?.firstName?.[1]}}
    </p>
  </div> -->
</div>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Logado como</span>
            <span class="mt-1.5 text-md font-medium">{{ user?.user?.email }}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Meu minibanco</span>
    </button> -->
    <button mat-menu-item (click)="toggleDarkTheme()">
        <mat-icon svgIcon="heroicons_outline:color-swatch"></mat-icon>
        <span>Mudar tema</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>Sair</span>
    </button>
</mat-menu>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
    selector: "Button",
    templateUrl: "./standard-button.component.html",
    styleUrls: ["./standard-button.component.scss"],
})
export class StandardButtonComponent implements OnInit {
    public buttonColor = "";
    public buttonStyle = "";

    private colors = {
        primary: "blue-800",
        accent: "gray-800",
        warn: "red-700",
        light: "gray-500",
    };

    private typeStyle = {
        solid: "bg-$color border-$color border-2 text-white",
        outline: "border-$color border-2 bg-transparent text-$color",
        icon: "bg-transparent text-$color hover:bg-gray-200",
        transparent: "bg-transparent text-$color",
    };

    // appearance
    @Input() matIcon?: string;
    @Input() heroIcon?: string;
    @Input() label?: string;
    @Input() color: "primary" | "accent" | "warn" | "light";
    @Input() type: "solid" | "outline" | "icon" | "transparent";
    @Input() hideLabel = true;
    @Input() classCustom? = "";

    // behavior
    @Input() disabled = false;
    @Input() navigateToUrl?: string;
    @Output() clickEvent = new EventEmitter();

    constructor(private readonly router: Router) { }

    ngOnInit() {
        this.buttonColor = this.colors[this.color];
        this.buttonStyle = this.typeStyle[this.type].replaceAll("$color", this.buttonColor);
    }

    clickAction() {
        if (this.disabled) return;
        if (this.navigateToUrl) {
            this.router.navigate([this.navigateToUrl]);
        }
        this.clickEvent.emit();
    }

    secondaryClickAction(event: MouseEvent) {
        if (this.disabled) return;
        if (event.which === 2 && !this.navigateToUrl) {
            return this.clickAction();
        }
        if (this.navigateToUrl) this.router.navigate([]).then(() => window.open(this.navigateToUrl, "_blank"));
    }

}

import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, finalize, switchMap } from "rxjs/operators";
import { ApiUtils } from "./api.utils";

@Injectable({ providedIn: "root" })
export class ApiService {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    get<T>(service: string, endpoint: string, options?): Observable<any> {
        const url = ApiUtils.build(service, endpoint);
        return this._httpClient.get<T>(url, options).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    post<T>(service: string, endpoint: string, body?): Observable<any> {
        const url = ApiUtils.build(service, endpoint);
        return this._httpClient.post<T>(url, body).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    makeFileRequest<T>(
        service: string,
        endpoint: string,
        params: unknown[],
        files: File[],
        observation?: string,
    ): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        const formData: FormData = new FormData();

        for (const file of files) {
            formData.append("files", file, file.name);
        }

        observation && formData.append("observacao", observation);

        params?.map((element) => {
            const keys = Object.keys(element);
            const values = Object.values(element);
            keys.map((key, i) => {
                if (key !== "files") {
                    formData.append(key, String(values[i]));
                }
            });
        });

        const req = new HttpRequest("POST", url, formData);

        return this._httpClient.request<T>(req).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    makeSingleFileRequest<T>(
        service: string,
        endpoint: string,
        params: unknown[],
        file: File,
        observation?: string,
    ): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        const formData: FormData = new FormData();

        formData.append("file", file, file.name);
        
        if (observation) formData.append("observacao", observation);

        params?.map((element) => {
            const keys = Object.keys(element);
            const values = Object.values(element);
            keys.forEach((key, i) => {
                formData.append(key, String(values[i]));
            });
        });

        const req = new HttpRequest("POST", url, formData);

        return this._httpClient.request<T>(req).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    patch<T>(service: string, endpoint: string, body: unknown): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        return this._httpClient.patch<T>(url, body).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    put<T>(service: string, endpoint: string, body: unknown): Observable<any> {
        const url = ApiUtils.build(service, endpoint);

        return this._httpClient.put<T>(url, body).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }

    delete<T>(service: string, endpoint: string, options?): Observable<any> {
        const url = ApiUtils.build(service, endpoint);
        return this._httpClient.delete<T>(url, options).pipe(
            catchError(ApiUtils.handleError),
            switchMap((response) => {
                return of(response);
            }),
            finalize(() => undefined),
        );
    }
}

import { PixKeyTypeEnum } from "app/components/forms/assignor-form-component/interfaces/assignor-form.interface";
import { IBasicInfoMinibank } from "../../minibanco/interfaces/basic-info-minibank.interface";

export interface IAssignorForm extends IAssignorDataForm {
    representative: IRepresentativeForm;
    address: IAddressForm;
    bankData?: IBankDataForm;
    customerDocuments?: IObservationDocuments;
    connectedMinibanks?: IConnectedMinibankAssignorForm;
    minibanks?: IBasicInfoMinibank[];
}

export interface IAssignorDataForm {
    document: string;
    naturalPerson: boolean;
    legalPerson: boolean;
    companyName: string;
    fantasyName: string;
    phone: string;
    email: string;
    offRisk: boolean;
    situation: boolean;
    digitalSignature: DigitalSignatureEnum;
    completeAnalysis?: boolean;
    simpleAnalysis?: boolean;
}

export interface IRepresentativeForm {
    document: string;
    name: string;
    companyEntryDate: Date | string;
    status: boolean;
}

export interface IAddressForm {
    zipCode: string;
    street: string;
    number: number;
    complement: string;
    neighborhood: string;
    city: string;
    state: string;
}

export interface IBankDataForm {
    bank: string;
    agency: number;
    agencyDigit: number;
    account: string;
    accountDigit: number;
    pixKeyType: PixKeyTypeEnum;
    pix: string;
}

export interface IConnectedMinibankAssignorForm {
    companyName: string;
}

export interface IObservationDocuments {
    observation: string;
}

export enum DigitalSignatureEnum {
    E_CPF = "E_CPF",
    E_CNPJ = "E_CNPJ" ,
    ELETRONICA = "ELETRONICA",
}
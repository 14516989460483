<div class="w-full min-w-120 p-2">
    <h1 class="text-3xl font-bold mb-4">Enviar documento da análise</h1>

    <form [formGroup]="documentsForm" class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Minibanco</mat-label>
            <input
                #minibanksInput
                matInput
                type="text"
                placeholder="Selecione o Mini Banco..."
                formControlName="minibank"
                [matAutocomplete]="autocomplete"
                (click)="minibankAutocomplete()"
            />
            <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete" [displayWith]="displayMinibankFn" title="Selecione o Mini Banco...">
                <mat-option class="bg-white w-full" *ngFor="let minibank of filteredMinibanks$ | async" [value]="minibank">
                    {{ minibank.companyName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Análises adicionais</mat-label>
            <input
                matInput
                type="text"
                formControlName="additionalAnalysisCount"
                maxlength="2"
                oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                placeholder="0"
            />
        </mat-form-field>

        <!-- Upload recursivo -->
        <form class="w-full h-auto" formArrayName="documentsArray">
            <ng-container *ngFor="let document of documentsArray.controls; index as i; first as isFirst">
                <div [formGroupName]="i" class="flex flex-col gap-2 pb-4">
                    <div class="w-full pb-4">
                        <label
                            class="w-full h-12 border-2 border-dashed rounded-lg px-6 flex justify-center items-center gap-2 cursor-pointer"
                            for="file-upload"
                            [ngClass]="
                                files[i]
                                    ? 'bg-green-50 hover:bg-green-200 border-green-600'
                                    : 'bg-blue-100 hover:bg-blue-200 border-blue-600'
                            "
                        >
                            <mat-icon class="icon-size-5" [ngClass]="files[i] ? 'text-green-800' : 'text-blue-800'">
                                {{ files[i] ? "attach_file" : "upload_file" }}
                            </mat-icon>

                            <span [ngClass]="files[i] ? 'text-green-800' : 'text-blue-800'">
                                {{ files[i] ? files[i].name : "Selecionar arquivo" }}
                            </span>
                            <input
                                formControlName="document"
                                id="file-upload"
                                type="file"
                                class="hidden"
                                max-uploads="1"
                                (change)="handleFileInput($event)"
                            />
                        </label>
                    </div>
                    <span>Gostaria de deixar uma observação? <span class="text-gray-500">(opcional)</span></span>
                    <div>
                        <mat-form-field class="w-full max-h-40">
                            <textarea
                                matInput
                                [maxlength]="observationMaxLength"
                                placeholder="Observações"
                                formControlName="observation"
                            ></textarea>
                        </mat-form-field>
                        <div class="w-full text-right text-sm text-gray-700">
                            {{ documentsArray.value[i].observation?.length || 0 }}/{{ observationMaxLength }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </form>
    <div class="w-full flex justify-between items-center gap-4">
        <Button
            [disabled]="isLoading"
            (clickEvent)="cancel()"
            type="outline"
            label="Cancelar"
            classCustom="border-transparent py-0 px-0 text-gray-500"
        ></Button>

        <Button
            [disabled]="isLoading"
            (clickEvent)="submit()"
            color="primary"
            type="solid"
            label="Enviar"
            classCustom="py-2 px-8"
        ></Button>
    </div>
</div>

/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import {
    FindAllMinibancosGQL,
    FindAllMinibancosQuery,
    FindManyCedentesGQL,
    FindOneMinibancoGQL,
    FindOneMinibancoQuery,
    GetBasicInformationsOfMinibancosGQL,
    GetBasicInformationsOfMinibancosQuery,
    GetCedentesOfMinibancoGQL,
    GetTakeRatesOfMinibancoGQL,
    QueryMode,
} from "generated/graphql";

import { ApiService } from "../../../core/api/api.service";

import { Minibanco } from "./interfaces/minibanco.types";

import { IBasicInfoMinibank } from "app/modules/admin/minibanco/interfaces/basic-info-minibank.interface";
import { ITakeRateMinibanco } from "./interfaces/take-rate.types";
import { ITaxasMinibanco } from "./interfaces/taxas-minibanco.types";
import { InventoryPagination } from "./minibanco.component";
import { IEditMinibanco } from "./interfaces/edit-minibanco.types";

@Injectable({ providedIn: "root" })
export class MinibancoService {
    private readonly service: string = "crm";
    private readonly endpointDefault: string = "/minibanks";

    private _minibancos: BehaviorSubject<FindAllMinibancosQuery["minibancos"] | null> = new BehaviorSubject(null);

    private _minibancosBasicInfo: BehaviorSubject<GetBasicInformationsOfMinibancosQuery["minibancos"] | null> =
        new BehaviorSubject(null);

    private _minibanco: BehaviorSubject<FindOneMinibancoQuery["minibanco"] | null> = new BehaviorSubject(null);

    private _takeRatesOfMinibanco: BehaviorSubject<ITakeRateMinibanco[] | null> = new BehaviorSubject(null);

    private _taxasOfMinibanco: BehaviorSubject<null> = new BehaviorSubject(null);

    private _pagination: BehaviorSubject<InventoryPagination | null> = new BehaviorSubject(null);

    constructor(
        private readonly findAllMinibancos: FindAllMinibancosGQL,
        private readonly findOneMinibanco: FindOneMinibancoGQL,
        private readonly getTakeRatesOfMinibancoGQL: GetTakeRatesOfMinibancoGQL,
        private readonly getBasicInformationsOfMinibanco: GetBasicInformationsOfMinibancosGQL,
        private readonly getCedentesOfMinibanco: GetCedentesOfMinibancoGQL,
        private readonly findManyCedentes: FindManyCedentesGQL,
        private readonly apiService: ApiService,
    ) {}

    get pagination$(): Observable<InventoryPagination> {
        return this._pagination.asObservable();
    }

    get minibancos$(): Observable<FindAllMinibancosQuery["minibancos"]> {
        return this._minibancos.asObservable();
    }

    set minibancos(val: FindAllMinibancosQuery["minibancos"]) {
        this._minibancos.next(val);
    }

    get minibancosBasicInfo$(): Observable<GetBasicInformationsOfMinibancosQuery["minibancos"]> {
        return this._minibancosBasicInfo.asObservable();
    }

    set minibancosBasicInfo(val: GetBasicInformationsOfMinibancosQuery["minibancos"]) {
        this._minibancosBasicInfo.next(val);
    }

    get minibanco$(): Observable<FindOneMinibancoQuery["minibanco"]> {
        return this._minibanco.asObservable();
    }

    set minibanco(val: FindOneMinibancoQuery["minibanco"]) {
        this._minibanco.next(val);
    }

    get takeRateOfMinibanco$(): Observable<ITakeRateMinibanco[]> {
        return this._takeRatesOfMinibanco.asObservable();
    }

    set takeRateOfMinibanco(val) {
        this._takeRatesOfMinibanco.next(val);
    }

    get taxasOfMinibanco$() {
        return this._taxasOfMinibanco.asObservable();
    }

    set taxasOfMinibanco(val) {
        this._taxasOfMinibanco.next(val);
    }

    getListAllMinibancos() {
        return this.findAllMinibancos.fetch().pipe(
            map(({ data: { minibancos } }) => {
                this.minibancos = minibancos;
                return minibancos;
            }),
        );
    }

    async getAllMinibanks() {
        return this.apiService.get("crm", "/minibanco").toPromise();
    }

    async getPlans(): Promise<{ data: Array<{ id: string; type: string; attributes: { name: string } }> }> {
        return this.apiService.get("oracle", "/bff-front-backoffice/plans").toPromise();
    }
    async getCategories(): Promise<Array<{ id: string; name: string }>> {
        return this.apiService.get("crm", "/minibanco/categories").toPromise();
    }

    getMinibanco(minibancoId: number) {
        return this.findOneMinibanco.fetch({ minibancoId }).pipe(
            map((result) => {
                this.minibanco = result.data.minibanco;
            }),
        );
    }

    getMinibancosBasicInformations() {
        return this.getBasicInformationsOfMinibanco.fetch().pipe(
            map((result) => {
                this.minibancosBasicInfo = result.data.minibancos;
            }),
        );
    }

    getCedentesOfMinibancoList(minibancoId: number) {
        return this.getCedentesOfMinibanco
            .fetch({
                minibancoId,
                where: {},
                take: 10,
                skip: 0,
            })
            .pipe(map((result) => result.data.minibanco.cedentes));
    }

    async getAllCedentes() {
        const { data } = await this.apiService.get("crm", "/cedente").toPromise();

        return data.map(({ id, cnpj, razaoSocial, nomeFantasia }) => {
            return { id, cnpj, razaoSocial, nomeFantasia };
        });
    }

    updateCedentesSearchFilterByMinibancos(query: string) {
        return this.findManyCedentes
            .fetch({
                where: { razaoSocial: { mode: QueryMode.Insensitive, contains: query } },
                take: 10,
                skip: 0,
            })
            .pipe(map((result) => result.data.cedentes));
    }

    postMinibanco(minibanco: Minibanco): Observable<Minibanco> {
        return this.apiService.post("crm", "/minibanco", minibanco);
    }

    updateMinibanco(id: number, updatedObject: Partial<IEditMinibanco>) {
        return this.apiService.put("crm", `/minibanco/${id}`, updatedObject).pipe(
            tap((response) => {
                this.minibanco = response;
            }),
        );
    }

    updateStatusMinibanco(id: number, status: string) {
        return this.apiService
            .patch("crm", `/minibanco/${id}/status`, {
                status,
            })
            .pipe(
                tap((response) => {
                    this.minibanco = response;
                }),
            );
    }

    getTakeRatesOfMinibanco(minibancoId: number) {
        return this.getTakeRatesOfMinibancoGQL
            .fetch({ minibancoId })
            .pipe(map((result) => (this.takeRateOfMinibanco = result.data.takeRateMinibancos)));
    }

    updateTakeRatesOfMinibanco(minibancoId: number, dataTakeRates: ITakeRateMinibanco[]) {
        return this.apiService.post("crm", `/minibanco/${minibancoId}/take-rate`, dataTakeRates).pipe(
            tap((result) => {
                this.takeRateOfMinibanco = result;
                return result;
            }),
        );
    }

    deleteTakeRateOfMinibanco(minibancoId: number, dataTakeRate: ITakeRateMinibanco) {
        return this.apiService.delete("crm", `/minibanco/${minibancoId}/take-rate/${dataTakeRate.id}`);
    }

    getTaxasOfMinibanco(minibancoId: number) {
        if (minibancoId)
            return this.apiService.get("crm", `/minibanco/${minibancoId}/taxas`).pipe(
                tap((response) => {
                    this.taxasOfMinibanco = response;
                }),
            );
    }

    updateTaxasOfMinibanco(minibancoId: number, dataTaxas: ITaxasMinibanco) {
        return this.apiService.post("crm", `/minibanco/${minibancoId}/taxas`, dataTaxas);
    }

    // minibank service version 2
    getBasicInfoMinibank(): Observable<IBasicInfoMinibank[]> {
        return this.apiService.get(this.service, `${this.endpointDefault}/basic`);
    }

    uploadFile(files: File[]) {
        return this.apiService.makeFileRequest("oracle", "/bff-front-backoffice/file", null, files).toPromise();
    }
}

import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAX_FILE_SIZE } from "../../../utils/constants/file-size.constant";
import { NgxDropzoneChangeEvent } from "ngx-dropzone";

@Injectable({providedIn: "root"})
export class FileValidator {

    constructor(
        private readonly snackbar: MatSnackBar
    ) {}

    private maxFileSize = MAX_FILE_SIZE;
    private duration = 5000;

    validateSize(files: File[]): boolean {
        const hasOverSize = files.some((file) => file.size > this.maxFileSize);
        if (hasOverSize) this.showMaxSizeError();

        return !hasOverSize;
    }

    validateEvent(event: NgxDropzoneChangeEvent): boolean {
        if (event.rejectedFiles?.length) this.showMaxSizeError();

        return event.rejectedFiles?.length === 0;
    }

    private showMaxSizeError() {
        this.snackbar.open("Os arquivos não podem ser maiores que 5 MB", "x", {
            duration: this.duration,
            panelClass: "snack-bar-error",
        });
    }
}

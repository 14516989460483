<div class="rounded-md bg-white p-4">
    <span class="flex gap-2">
        <mat-icon class="icon-size-5 text-blue-600" [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>  
        <h1 class="text2xl text-gray-800">{{ title }}</h1>
    </span>
    <pre class="my-3 leading-normal text-gray-600 font-sans">{{ message }}</pre>
    <Button
        *ngIf="linkText"
        color="primary"
        type="icon"
        [hideLabel]="false"
        [navigateToUrl]="linkUrl"
        classCustom="mx-0"
    >
        {{ linkText }}
    </Button>
</div>

import { Injectable } from "@angular/core";
import { User } from "app/core/user/user.types";

@Injectable({
    providedIn: "root",
})
export class UserService {
    set user(token: User) {
        localStorage.setItem("user", JSON.stringify(token));
    }

    get user$(): User {
        const inititalUser = JSON.parse(localStorage.getItem("user")) ?? "";
        const permissoes = JSON.parse(localStorage.getItem("permissoes")) ?? "";
        return { ...inititalUser, permissoes: permissoes };
    }
}

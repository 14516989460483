/* eslint-disable @typescript-eslint/no-unsafe-call */
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { datadogRum } from "@datadog/browser-rum";
import { AppModule } from "app/app.module";
import { environment } from "environments/environment";
import * as amplitude from "@amplitude/analytics-browser";
import { User } from "app/core/user/user.types";

const IS_PRODUCTION = environment.production;
const TOKEN = localStorage.getItem("access_token");
const USER = JSON.parse(localStorage.getItem("user"));

datadogRum.init({
    applicationId: environment.vars.dataDogApplicationId,
    clientToken: environment.vars.dataDogClientToken,
    site: "us5.datadoghq.com",
    service: "front-backoffice",
    env: IS_PRODUCTION ? "production" : "staging",
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();


if (IS_PRODUCTION && TOKEN && USER) {
    const identify = new amplitude.Identify();
    const { minibanco: minibank, user } = USER as User;
    const { razaoSocial: companyName, nomeFantasia: fantasyName, id: minibankId } = minibank;
    const { email, firstName, id: userId } = user;

    const initAmplitude = () => {
        amplitude.init(environment.vars.amplitude.apiKey, String(userId), {
            logLevel: amplitude.Types.LogLevel.Warn,
            minIdLength: 1,
            defaultTracking: {
                sessions: true,
                formInteractions: true,
            },
        });

        identify.set("name", firstName);
        identify.set("email", email);
        identify.set("minibank_id", minibankId);
        identify.set("minibank_name", fantasyName || companyName);

        amplitude.identify(identify);
    };

    initAmplitude();
    amplitude.track("View app-backoffice-main");
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));

export const environment = {
    production: true,

    vars: {
        apis: {
            crm: "https://api-bankme-prd-01.bankme.tech",
            ccb: "https://api-ccb-prd-01.bankme.tech",
            oracle: "https://api-oracle-prd-01.bankme.tech",
            bi: "https://bi.bankme.app/api",
            sandbox: "http://sandbox.bankme.tech:9000",
            bff: "https://api-oracle-prd-01.bankme.tech/bff-front-backoffice",
        },
        amplitude: {
            apiKey: "b14ca311720108b69a988f69c0045160"
        },
        dataDogClientToken: "pubefb5382c509bb18be7333766cb2acf7a",
        dataDogApplicationId: "769fc78b-41ee-49bb-8090-354799e4e0ad",
        fastapiKey: "b543abf53743aecc4e0a5ae470286291",
        cedente: "https://credito.bankme.tech/",
        hj: "2717622",
        ga: "G-T9VEMG58KV",
        whitelist: [
            "https://viacep.com.br",
            "https://bi.bankme.tech",
            "https://exgk5qnmf0.execute-api.sa-east-1.amazonaws.com/events",
            "https://api-dev.bankme.tech/hubspot",
        ],
        pusher: "",
    },
};

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { InitialData } from "./app.types";
import { navigation } from "./core/navigation/navigation";

@Injectable({
    providedIn: "root",
})
export class InitialDataResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return of({
            navigation: {
                compact: navigation,
                default: navigation,
                futuristic: navigation,
                horizontal: navigation,
            },
        });
    }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AuthUtils } from "app/core/auth/auth.utils";
import { UserService } from "app/core/user/user.service";
import { environment } from "../../../environments/environment";

import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Entidade, Permissao, RolesInterface } from "app/modules/admin/usuarios/perfil/perfil.types";
import { User } from "../user/user.types";
import { MatSnackBar } from "@angular/material/snack-bar";

export interface LoginResponse {
    aceitouTermos: boolean;
    accessToken: string;
    permissoes: RolesInterface;
}

@Injectable()
export class AuthService {
    _authenticated: boolean;
    public token: string;
    aceitouTermos = false;

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _userService: UserService, private _router: Router, private _snackBar: MatSnackBar) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem("access_token", token);
    }

    get accessToken(): string {
        if (localStorage.getItem("access_token") == "undefined") {
            this.signOut();
        }
        return localStorage.getItem("access_token") ?? "";
    }

    /**
     * Setter & getter for permission
     */
    set permission(permissoes: RolesInterface) {
        localStorage.setItem("permissoes", JSON.stringify(permissoes));
    }

    get permission(): RolesInterface {
        if (localStorage.getItem("permissoes") === "undefined") {
            this.signOut();
        }
        return JSON.parse(localStorage.getItem("permissoes")) ?? "";
    }

    private parseJwt(token: string) {
        let base64Url: string;
        try {
            base64Url = token.split(".")[1];
        } catch (e) {
            location.reload();
            localStorage.clear();
        }
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(""),
        );

        return JSON.parse(jsonPayload) as User;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string) {
        return this._httpClient.post("api/auth/forgot-password", email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string, userId?: number) {
        return this._httpClient.post(
            `${environment.vars.apis.crm}/auth/resetar-senha${userId ? "/" + userId.toString() : ""}`,
            { newPassword: password },
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<LoginResponse | boolean> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError("User is already logged in.");
        }

        return this._httpClient.post(`${environment.vars.apis.crm}/auth/login`, credentials).pipe(
            switchMap((response: LoginResponse) => {
                // Store the access token in the local storage

                const parsedJWT = this.parseJwt(response.accessToken);
                if (!parsedJWT.user.isAdmin) {
                    this._authenticated = false;
                    return of(false);
                }

                this.accessToken = response.accessToken;

                this.permission = response.permissoes;

                this._userService.user = parsedJWT;

                // Set the authenticated flag to true

                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            }),
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken() {
        // Renew token
        return this._httpClient
            .post(`${environment.vars.apis.crm}/auth/refresh-token`, {
                access_token: this.accessToken,
            })
            .pipe(
                catchError(() => {
                    // Return false
                    return of(false);
                }),
                switchMap((response: { accessToken: string }) => {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Set the authenticated flag to true

                    this._authenticated = true;

                    // Store the user on the user service

                    this._userService.user = this.parseJwt(response.accessToken);

                    // Return true
                    return of(true);
                }),
            );
    }

    /**
     * Sign out
     */
    signOut() {
        // Remove the access token from the local storage
        localStorage.clear();

        // Set the authenticated flag to false
        this._authenticated = false;

        this._router.navigateByUrl("/sign-in");

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }) {
        return this._httpClient.post("api/auth/sign-up", user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }) {
        return this._httpClient.post("api/auth/unlock-session", credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            localStorage.clear();
            window.location.reload();
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    canActivate(routeAc: ActivatedRouteSnapshot): boolean {
        this.token = localStorage.getItem("access_token");

        if (!this.token) {
            this._router.navigate(["/login"]);
            return false;
        }
        
        if (this.token && routeAc.url.toString() === "login") {
            this._router.navigate(["/"]);
            return false;
        }

        if (this.token && AuthUtils.isTokenExpired(this.token)) {
            this.signOut();
        }

        if (
            routeAc.url.toString() === "" ||
            routeAc.url.toString() === "home" ||
            routeAc.url.toString() === "usuario/minha-conta"
        ) {
            return true;
        }

        // const userPermitions = this.checkPermission(routeAc.data.permissoes);

        // if (!userPermitions) {
        //   this._snackBar.open(
        //     "Você não tem permissão para acessar esta página",
        //     "OK",
        //   );
        // }

        return true;
    }

    checkPermission(routePermissoes: Partial<RolesInterface>): boolean {
        const permissoes = this._userService.user$.permissoes;
        const perfil = Object.entries(routePermissoes);

        const isAllowed = perfil.every(([role, permissao]: [Entidade, Permissao[]]) => {
            if (Object.prototype.hasOwnProperty.call(permissoes, role)) {
                return (
                    permissoes[role].includes("MANAGE") ||
                    permissao.some((itemPermissao) => permissoes[role].includes(itemPermissao))
                );
            }
            return false;
        });

        return isAllowed;
    }
}
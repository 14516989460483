import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IAssignorDocumentType } from "./interfaces/document-types.interface";
import { UploadModalService } from "../modals/upload-modal/upload-modal.service";
import { IDocumentUpload } from "./interfaces/document-upload.interface";

@Injectable({ providedIn: "root" })
export class DocumentsUploadService {
    constructor(
        private readonly http: ApiService
    ) {}

    getDocumentTypes(): Promise<IAssignorDocumentType[]> {
        return this.http.get("crm", "/assignor/document-types").toPromise();
    }

    uploadFile({
        assignorId,
        file,
        observation,
        minibankId,
        documentType,
    }: { file: File; assignorId: number; documentType: number; observation?: string; minibankId: number }) {
        return this.http
            .makeFileRequest("crm", `/cedente/upload/${assignorId}`, [{
                minibankId,
                documentType,
            }],
                [file], 
                observation,
            )
            .toPromise();
    }
}

import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { IBasicInfoMinibank } from "app/modules/admin/minibanco/interfaces/basic-info-minibank.interface";
import { MinibancoService as MinibankService } from "app/modules/admin/minibanco/minibanco.service";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, map, startWith, takeUntil } from "rxjs/operators";
import { IAssignorDocumentType } from "./interfaces/document-types.interface";
import { DocumentsUploadService } from "./documents-upload.service";
import { IDocumentUpload } from "./interfaces/document-upload.interface";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FileValidator } from "app/shared/services/file-validator/file-validator.service";

@Component({
    selector: "documents-upload",
    templateUrl: "./documents-upload.component.html",
})
export class DocumentsUploadComponent implements OnInit {
    @Input() assignorId: number;
    @Output() willCloseAndEmitUpdate = new EventEmitter<boolean>();
    @ViewChild("minibanksInput") minibanksInput: ElementRef<HTMLInputElement>;

    public unsubscribeAll = new Subject<void>();

    public listAllMinibanks: IBasicInfoMinibank[] = [];
    public filteredMinibanks$: Observable<IBasicInfoMinibank[]>;

    public documentTypes: BehaviorSubject<IAssignorDocumentType[]> = new BehaviorSubject([]);
    public documentsForm: FormGroup;
    public files: File[] = [];
    public observationMaxLength = 350;
    public maxFileSize = 5000000;
    public minibankToConnect: IBasicInfoMinibank;
    public isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            assignorId: number;
        },
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private readonly minibankService: MinibankService,
        private readonly documentsUploadService: DocumentsUploadService,
        private readonly fileValidator: FileValidator,
    ) {
        this.assignorId = data.assignorId;
    }

    ngOnInit() {
        this.documentsUploadService.getDocumentTypes().then((res) => {
            this.documentTypes.next(res);
        });

        this.documentsForm = this.formBuilder.group({
            minibank: [null, Validators.required],
            documentsArray: this.formBuilder.array([
                this.formBuilder.group({
                    documentType: [null, Validators.required],
                    document: [null, Validators.required],
                    observation: [null, Validators.maxLength(this.observationMaxLength)],
                }),
            ]),
        });
    }

    get documentsArray() {
        return this.documentsForm.get("documentsArray") as FormArray;
    }

    addMoreDocuments() {
        const arrForm = {
            documentType: [null, Validators.required],
            document: [null, Validators.required],
            observation: [null],
        };

        this.documentsArray.push(this.formBuilder.group(arrForm));
    }

    removeDocument(i: number) {
        if (this.documentsArray.length === 1) {
            this.files = [];
            this.documentsForm.reset();
            return;
        }

        this.documentsArray.removeAt(i);
        this.files.splice(i, 1);
        this.updateFormValidation();
    }

    handleFileInput(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (!this.fileValidator.validateSize([file])) return;
        
        this.files.push(file);
        this.files.forEach((doc, i) => {
            if (doc === undefined) this.files.splice(i, 1);
        });
        this.updateFormValidation();
    }

    updateFormValidation() {
        this.documentsForm.updateValueAndValidity();
    }

    minibankAutocomplete() {
        this.minibankService
            .getBasicInfoMinibank()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((response) => {
                this.listAllMinibanks = response;
            });

        this.filteredMinibanks$ = this.documentsForm.controls.minibank.valueChanges.pipe(
            startWith(""),
            map((minibank: string | IBasicInfoMinibank) => {
                if (typeof minibank === "string") {
                    return minibank;
                }

                this.minibankToConnect = minibank;
                return minibank?.companyName;
            }),
            debounceTime(300),
            map((companyName) => {
                if (companyName) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    return this.listAllMinibanks.filter((minibank) =>
                        minibank.companyName?.toLowerCase().includes(companyName.toLowerCase()),
                    );
                }
                return this.listAllMinibanks.slice();
            }),
        );
    }

    displayMinibankFn(minibank: IBasicInfoMinibank) {
        return minibank?.fantasyname ? minibank.fantasyname : minibank.companyName;
    }

    cancel() {
        this.documentsForm.reset();
        this.files = [];
        this.willCloseAndEmitUpdate.emit(false);
    }

    async submit() {
        const minibankId = this.documentsForm.controls.minibank.value.id;
        if (!minibankId) return this.snackBar.open("Selecione um Minibanco", "x", { duration: 2000 });

        if (this.documentsArray.length !== this.files.length) {
            this.snackBar.open("Alguns arquivos não foram selecionados corretamente.", "x", {
                duration: 2000,
            });
            return;
        }

        this.documentsForm.disable();
        this.isLoading = true;
        const filesToUpload: IDocumentUpload[] = [];
        const formData = this.documentsForm.value;

        formData.documentsArray.forEach((doc, index: number) => {
            filesToUpload.push({
                file: this.files[index],
                documentType: doc.documentType,
                observation: doc.observation,
            });
        });

        const uploadPromises = filesToUpload.map(async ({ file, documentType, observation }) => {
            await this.documentsUploadService.uploadFile({
                file,
                observation,
                documentType,
                minibankId,
                assignorId: this.assignorId,
            });
        });

        try {
            await Promise.all(uploadPromises);
            this.willCloseAndEmitUpdate.emit(true);
        } catch (error) {
            this.snackBar.open("Ocorreu um erro ao enviar os arquivos. Recarregue a página e tente novamente.", "x", {
                duration: 3500,
            });
            this.isLoading = false;
            this.documentsForm.enable();
        }
    }
}

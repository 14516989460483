import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Cedente, Responsavel } from "./cedente.types";

import {
    IAllBrazilianBanks,
    ICreditAnalysisAssignor,
    ITypeDocument,
    IUpdateCreditAnalysisAssignor,
} from "app/components/forms/assignor-form-component/interfaces/assignor-form.interface";
import { AddressService } from "app/modules/admin/address/address.service";
import { IAssignor, IUpdateAssignor } from "app/modules/admin/cedente/interfaces/assignor.interface";
import {
    CedenteGQL,
    CedenteQuery,
    CedentesInfoWhereInput,
    FindManyCedentesGQL,
    QueryMode,
    SortOrder,
    StatusOperacional,
    StatusOperacionalCedenteGQL,
    StatusOperacionalCedenteQuery,
} from "../../../../generated/graphql";
import { PaginationResponse } from "../../../app.types";
import { Contact } from "../../../components/cards/contact-card/contact-card.interfaces";
import { ApiService } from "../../../core/api/api.service";
import { Pagination } from "../empresa/empresa.component";
import { ICreateBankAccount } from "./interfaces/create-bank-account.interface";

@Injectable({
    providedIn: "root",
})
export class CedenteService {
    private readonly service: string = "crm";
    private readonly endpoint: string = "/assignor";
    private readonly fileUploadEndpoint: string = "/cedente/upload/:id";

    getClienteAprovacao(_id: unknown): Observable<Cedente> {
        throw new Error("Method not implemented.");
    }

    private _clientes = new BehaviorSubject<Cedente[]>([]);
    private _cliente = new BehaviorSubject<CedenteQuery["cedente"]>(null);
    private _statusOperacionalCedente = new BehaviorSubject<
        StatusOperacionalCedenteQuery["statusOperacionalCedenteMinibancos"]
    >(null);

    private _clienteResponsaveis = new BehaviorSubject<Responsavel[]>([]);
    private _pagination = new BehaviorSubject<Pagination>(null);

    public willUpdateCreditAnalysis = new Subject();

    constructor(
        private readonly apiService: ApiService,
        private readonly addressService: AddressService,
        private readonly cedenteGQL: CedenteGQL,
        private readonly statusOperacionalCedenteGQL: StatusOperacionalCedenteGQL,
        private readonly findManyCedentes: FindManyCedentesGQL,
    ) {}

    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    get cliente$(): Observable<CedenteQuery["cedente"]> {
        return this._cliente.asObservable();
    }

    set cliente(val: CedenteQuery["cedente"]) {
        this._cliente.next(val);
    }

    get statusOperacionalOfCliente$(): Observable<StatusOperacionalCedenteQuery["statusOperacionalCedenteMinibancos"]> {
        return this._statusOperacionalCedente.asObservable();
    }

    set statusOperacionalOfCliente(val: StatusOperacionalCedenteQuery["statusOperacionalCedenteMinibancos"]) {
        this._statusOperacionalCedente.next(val);
    }

    get responsaveis$(): Observable<Responsavel[]> {
        return this._clienteResponsaveis.asObservable();
    }

    set resposaveis(val: Responsavel[]) {
        this._clienteResponsaveis.next(val);
    }

    get clientes$(): Observable<Cedente[]> {
        return this._clientes.asObservable();
    }

    set clientes(val: Cedente[]) {
        this._clientes.next(val);
    }

    listaClientes(page = 1, take = 10, orderBy = "createdAt", sort: "asc" | "desc" | null = "desc", search = "") {
        return this.apiService
            .get("crm", "/cedente", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search: search,
                },
            })
            .pipe(
                tap((response: PaginationResponse<Cedente>) => {
                    this._pagination.next({
                        length: response.total,
                        size: response.data.length,
                        page: response.page,
                        lastPage: response.lastPage,
                        startIndex: 0,
                        endIndex: 10,
                    });

                    this._clientes.next(response.data);
                }),
            );
    }

    listaClientesAlteracao(
        page = 1,
        take = 10,
        orderBy = "createdAt",
        sort: "asc" | "desc" | "" = "desc",
        search = "",
    ) {
        return this.apiService
            .get("crm", "/cedente/sugestao/alteracao", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search: search,
                },
            })
            .pipe(
                tap((response: PaginationResponse<{ cedente: Cedente }>) => {
                    this._pagination.next({
                        length: response.total,
                        size: response.data.length,
                        page: response.page,
                        lastPage: response.lastPage,
                        startIndex: 0,
                        endIndex: 10,
                    });
                    this._clientes.next(response.data.map((item) => item.cedente));
                }),
            );
    }

    listaClientesCadastro(page = 1, take = 10, orderBy = "createdAt", sort: "asc" | "desc" | "" = "desc", search = "") {
        return this.apiService
            .get("crm", "/cedente/cadastros", {
                params: {
                    page: page > 0 ? page : 1,
                    take,
                    orderBy,
                    sort,
                    search: search,
                    status: "EM_ANALISE",
                },
            })
            .pipe(
                tap((response: PaginationResponse<Cedente>) => {
                    return {
                        pagination: {
                            length: response.total,
                            size: response.data.length,
                            page: response.page,
                            lastPage: response.lastPage,
                            startIndex: 0,
                            endIndex: 10,
                        },
                        clientes: {
                            ...response.data.map((item) => ({ ...item.campos, id: item.id })),
                        },
                    };
                }),
            );
    }

    listaCedenteResponsaveis(cedenteId: number) {
        return this.apiService
            .get("crm", `/cedente/${cedenteId}/vinculos`, {
                params: {
                    page: 1,
                    take: 100,
                    orderBy: "createdAt",
                    sort: "desc",
                    search: "",
                },
            })
            .pipe(
                tap((res: PaginationResponse<Responsavel>) => {
                    this._clienteResponsaveis.next(res.data);
                    return res;
                }),
            );
    }

    async getCliente(cedenteId: number) {
        const {
            data: { cedente },
        } = await this.cedenteGQL.watch().refetch({ cedenteId });

        this.cliente = cedente;

        return cedente;
    }

    getStatusOperacionalOfCedente(cedenteId: number) {
        return this.statusOperacionalCedenteGQL.fetch({ where: { cedenteId: { equals: cedenteId } } }).pipe(
            map((res) => {
                const auditSortedByDate = res.data.statusOperacionalCedenteMinibancos.map((item) => {
                    if (item.StatusOperacionalAudit.length > 0) {
                        return {
                            ...item,
                            StatusOperacionalAudit: item.StatusOperacionalAudit.slice().sort(
                                (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                            ),
                        };
                    } else {
                        return item;
                    }
                });

                this.statusOperacionalOfCliente = auditSortedByDate;
                return res.data.statusOperacionalCedenteMinibancos;
            }),
        );
    }

    changeStatusOperacionalOfCedente(
        cedenteId: number,
        minibancoId: number,
        status: StatusOperacional,
        observacao: string = null,
    ) {
        return this.apiService.put("crm", `/status-operacional/${cedenteId}/${minibancoId}`, { status, observacao });
    }

    editOperationalStatusComments(statusId: number, commentEdited: string) {
        return this.apiService.patch("crm", `/status-operacional/${statusId}/comments`, { comments: commentEdited });
    }

    updateCedentesSearchFilterByName(query: string) {
        return this.findManyCedentes
            .fetch({
                where: { razaoSocial: { mode: QueryMode.Insensitive, contains: query } },
                take: 10,
                skip: 0,
            })
            .pipe(map((result) => result.data.cedentes));
    }

    aprovarVinculo(vinculoId: number) {
        return this.apiService.post("crm", `/cedente/vinculos/${vinculoId}/aprovar`);
    }

    rejeitarVinculo(vinculoId: number, observacao: string) {
        return this.apiService.post("crm", `/cedente/vinculos/${vinculoId}/reprovar`, { observacao });
    }

    revogarVinculo(vinculoId: number) {
        return this.apiService.post("crm", `/cedente/vinculos/${vinculoId}/desvincular`);
    }

    restaurarVinculo(vinculoId: number) {
        return this.apiService.post("crm", `/cedente/vinculos/${vinculoId}/restaurar`);
    }

    aprovarCedente(cedenteId: number) {
        return this.apiService.post("crm", `/cedente/cadastros/${cedenteId}/aprovar`);
    }

    rejeitarCedente(cadastroId: number, observacao: string) {
        return this.apiService.post("crm", `/cedente/cadastros/${cadastroId}/reprovar`, { observacao });
    }

    aprovarSugestao(fieldId: number) {
        return this.apiService.post("crm", `/cedente/sugestao/alteracao/${fieldId}/aprovar`);
    }

    rejeitarSugestao(fieldId: number) {
        return this.apiService.post("crm", `/cedente/sugestao/alteracao/${fieldId}/recusar`);
    }

    updateSugestaoCedente(cedenteId: number, status: string) {
        return this.apiService.put("crm", `/cedente/${cedenteId}`, { status });
    }

    addCliente(value) {
        return this.apiService.post("crm", "/cedente", value);
    }

    editCliente(value, cedenteId: number) {
        return this.apiService.put("crm", `/cedente/${cedenteId}`, value);
    }

    getAddressByZipCode(zipCode: string) {
        return this.addressService.getAddressByZipCode(zipCode);
    }

    search(query: string) {
        return this.apiService.get("crm", "/cedente", {
            params: {
                page: 1,
                take: 30,
                orderBy: "id",
                sort: "asc",
                search: query,
            },
        });
    }

    updateNaoAntecipa(cedenteId: number, allowAntecipa: boolean) {
        return this.apiService.put("crm", `/cedente/${cedenteId}`, {
            naoAntecipa: allowAntecipa,
        });
    }

    getContatos(clienteId: number, page = 1) {
        return this.apiService.get("crm", `/cedente/${clienteId}/contatos?page=${page}&take=${12}`) as Observable<
            PaginationResponse<Contact>
        >;
    }

    editContato(contact: Contact) {
        return this.apiService.put("crm", `/cedente/${Number(this.cliente.id)}/contatos/${contact.id}`, contact);
    }

    deleteContato(cedenteId: number, contactId: number) {
        return this.apiService.delete("crm", `/cedente/${cedenteId}/contatos/${contactId}`);
    }

    criarCedente(data) {
        return this.apiService.post("crm", "/cedente-service/auth/cadastrar", data);
    }

    preCadastroCedente(idCedente: number) {
        return this.apiService.post("crm", `/cedente-service/auth/pre-cadastro/${idCedente}`);
    }

    // assignor service verion 2
    // new service
    getAssignorById(assignorId: number) {
        return this.apiService.get(this.service, `${this.endpoint}/${assignorId}`);
    }

    createAssignor(newAssignorData: IAssignor) {
        return this.apiService.post(this.service, this.endpoint, newAssignorData);
    }

    updateAssignor(assignorId: number, assignorUpdatedData: IUpdateAssignor) {
        return this.apiService.put(this.service, `${this.endpoint}/${assignorId}`, assignorUpdatedData);
    }

    async uploadAssignorDocuments(
        assignorId: number,
        files: File[],
        observation?: string,
        typeDocument?: ITypeDocument,
    ) {
        return this.apiService
            .makeFileRequest(
                this.service,
                this.fileUploadEndpoint.replace(":id", String(assignorId)),
                [{ documentType: Number(typeDocument.id), minibankId: 1 }],
                files,
                observation,
            )
            .toPromise();
    }
    unbindingMinibankOfAssignor(assignorId: number, minibankId: number) {
        return this.apiService.put(this.service, `${this.endpoint}/${assignorId}/unbinding-minibank`, { minibankId });
    }

    async fetchBanks(): Promise<IAllBrazilianBanks[]> {
        return this.apiService.get(this.service, "/v2/banks").toPromise();
    }

    async createCreditAnalysis(params: ICreditAnalysisAssignor) {
        return this.apiService.post(this.service, `/v2${this.endpoint}/credit-analysis`, params).toPromise();
    }

    async getMinibanksByAssignor(assignorId: number) {
        return this.apiService.get(this.service, `/v2${this.endpoint}/${assignorId}/minibanks`).toPromise();
    }

    updateCreditAnalysis(_assignorId: number, assignorStatusId: number, params: IUpdateCreditAnalysisAssignor) {
        return this.apiService
            .put(this.service, `/v2${this.endpoint}/credit-analysis/${assignorStatusId}`, params)
            .toPromise();
    }

    async getAllCreditAnalysisByAssignor(assignorId: number) {
        return this.apiService.get(this.service, `/v2${this.endpoint}/${assignorId}/all-credit-analysis`).toPromise();
    }

    async updateStatusCreditAnalysisByAssignor({
        creditAnalysisId,
        status,
    }: {
        creditAnalysisId: number;
        status: string;
    }) {
        return this.apiService
            .put(this.service, `/v2${this.endpoint}/credit-analysis/${creditAnalysisId}`, { status })
            .toPromise();
    }

    async editObservationAssignorAttachment({
        assignorAttachmentId,
        observation,
    }: {
        assignorAttachmentId: number;
        observation: string;
    }) {
        return this.apiService
            .put(this.service, `/v2${this.endpoint}/${1}/assignor-attachment/${assignorAttachmentId}`, { observation })
            .toPromise();
    }

    async sendToQueue(assignorId: number) {
        return this.apiService.get("crm", `/v2/assignor/retry-to-queue/${assignorId}`).toPromise();
    }

    async getAllBankAccountsByAssignor(assignorId: number) {
        return this.apiService.get("oracle", `/bff-front-bankme/assignors/${assignorId}/bank-accounts`).toPromise();
    }

    getAllAssignors(
        params: CedentesInfoWhereInput & {
            take: number;
            page: number;
            orderBy: SortOrder;
        },
    ) {
        return this.apiService.get("oracle", "/bff-front-backoffice/assignor", { params }).toPromise();
    }

    deactiveBankAccount(bankAccountId: number) {
        return this.apiService.delete("oracle", `/bff-front-bankme/assignors/bank-account/${bankAccountId}`);
    }

    createBankAccount(params: ICreateBankAccount, id: number) {
        return this.apiService.post("oracle", `/bff-front-bankme/assignors/${id}/bank-account`, params).toPromise();
    }
}

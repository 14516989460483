<div class="w-full min-w-120 p-2">
    <h1 class="text-3xl font-bold mb-4">Adicionar documentos</h1>

    <form [formGroup]="documentsForm" class="w-full">
        <mat-form-field class="w-full">
            <mat-label>Minibanco</mat-label>
            <input
                #minibanksInput
                matInput
                type="text"
                placeholder="Selecione o Mini Banco..."
                formControlName="minibank"
                [matAutocomplete]="autocomplete"
                (click)="minibankAutocomplete()"
            />
            <mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete" [displayWith]="displayMinibankFn">
                <mat-option class="bg-white w-full" *ngFor="let minibank of filteredMinibanks$ | async" [value]="minibank">
                    {{ minibank.companyName }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <hr class="h-0 mt-0 border-t-2 border-gray-200" />

        <!-- Upload recursivo -->
        <form class="w-full max-h-60 overflow-y-scroll" formArrayName="documentsArray">
            <ng-container *ngFor="let document of documentsArray.controls; index as i; first as isFirst">
                <div [formGroupName]="i" class="flex flex-col gap-2 pb-6">
                    <div class="flex justify-between">
                        <span>Tipo de documento</span>
                        <button
                            *ngIf="!isLoading"
                            type="button"
                            (click)="removeDocument(i)"
                            class="text-gray-600 hover:text-red-600"
                        >
                            <mat-icon class="icon-size-6" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                        </button>
                    </div>
                    <div class="w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-4 pb-4">
                        <mat-form-field>
                            <mat-select placeholder="selecione" formControlName="documentType">
                                <mat-option
                                    class="bg-white w-full"
                                    *ngFor="let type of documentTypes | async"
                                    [value]="type.id"
                                    >{{ type.description }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>

                        <label
                            class="w-full h-12 border-2 border-dashed rounded-lg px-6 flex justify-center items-center gap-2 cursor-pointer"
                            for="file-upload"
                            [ngClass]="
                                files[i]
                                    ? 'bg-green-50 hover:bg-green-200 border-green-600'
                                    : 'bg-blue-100 hover:bg-blue-200 border-blue-600'
                            "
                        >
                            <mat-icon class="icon-size-5" [ngClass]="files[i] ? 'text-green-800' : 'text-blue-800'">
                                {{ files[i] ? "attach_file" : "upload_file" }}
                            </mat-icon>

                            <span [ngClass]="files[i] ? 'text-green-800' : 'text-blue-800'">
                                {{ files[i] ? files[i].name : "Selecionar arquivo" }}
                            </span>
                            <input
                                formControlName="document"
                                id="file-upload"
                                type="file"
                                class="hidden"
                                max-uploads="1"
                                (change)="handleFileInput($event)"
                            />
                        </label>
                    </div>
                    <span>Gostaria de deixar uma observação? <span class="text-gray-500">(opcional)</span></span>
                    <div>
                        <mat-form-field class="w-full max-h-40">
                            <textarea
                                matInput
                                [maxlength]="observationMaxLength"
                                placeholder="Observações"
                                formControlName="observation"
                            ></textarea>
                        </mat-form-field>
                        <div class="w-full text-right text-sm text-gray-700">
                            {{ documentsArray.value[i].observation?.length || 0 }}/{{ observationMaxLength }}
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </form>
    <button
        class="p-2 text-center text-blue-800 font-bold flex justify-center items-center gap-2"
        (click)="addMoreDocuments()"
    >
        <mat-icon class="icon">add</mat-icon>
        Adicionar mais documentos
    </button>
    <div class="w-full py-4 flex justify-end gap-4">
        <Button
            [disabled]="isLoading"
            (clickEvent)="cancel()"
            color="warn"
            type="solid"
            label="Cancelar"
            matIcon="close"
        ></Button>

        <Button
            [disabled]="isLoading"
            (clickEvent)="submit()"
            color="primary"
            type="solid"
            label="Enviar"
            matIcon="done"
        ></Button>
    </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiService } from "app/core/api/api.service";

@Injectable({ providedIn: "root" })
export class AddressService {
    private readonly service: string = "crm";
    private readonly endpoint: string = "/address";

    constructor(private apiService: ApiService, private readonly httpClient: HttpClient) {}

    getAddressByZipCode(zipCode: string) {
        return this.httpClient.get(`https://viacep.com.br/ws/${zipCode}/json`);
        // return this.apiService.get(this.service, `${this.endpoint}/${zipCode}`);
    }
}

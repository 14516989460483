import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MinibancoService as MinibankService } from "app/modules/admin/minibanco/minibanco.service";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { IAssignorDocumentType, IMinibankAssignorStatus } from "./interfaces/document-types.interface";
import { IDocumentUpload } from "./interfaces/document-upload.interface";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DocumentCreditAnalysisModalService } from "./document-credit-analysis-modal.service";
import { CedenteService } from "app/modules/admin/cedente/cedente.service";
import { CreditAnalysisStatusEnum } from "app/components/forms/assignor-form-component/interfaces/assignor-form.interface";
import { FileValidator } from "app/shared/services/file-validator/file-validator.service";

@Component({
    selector: "app-document-credit-analysis-modal",
    templateUrl: "./document-credit-analysis-modal.component.html",
})
export class DocumentCreditAnalysisModalComponent implements OnInit {
    @Input() assignorId: number;
    @Output() willCloseAndEmitUpdate = new EventEmitter<boolean>();
    @ViewChild("minibanksInput") minibanksInput: ElementRef<HTMLInputElement>;

    public unsubscribeAll = new Subject<void>();

    public listAllMinibanks: IMinibankAssignorStatus[] = [];
    public filteredMinibanks$: Observable<IMinibankAssignorStatus[]>;

    public documentTypes: BehaviorSubject<IAssignorDocumentType[]> = new BehaviorSubject([]);
    public documentsForm: FormGroup;
    public files: File[] = [];
    public observationMaxLength = 350;
    public maxFileSize = 5000000;
    public minibankToConnect: IMinibankAssignorStatus;
    public isLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            assignorId: number;
        },
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private readonly minibankService: MinibankService,
        private readonly documentsUploadService: DocumentCreditAnalysisModalService,
        private readonly assignorService: CedenteService,
        private readonly fileValidator: FileValidator,
    ) {
        this.assignorId = data.assignorId;
    }

    async ngOnInit() {
        this.documentsUploadService.getDocumentTypes().then((res) => {
            this.documentTypes.next(res);
        });

        this.documentsForm = this.formBuilder.group({
            minibank: [null, Validators.required],
            additionalAnalysisCount: [null],
            documentsArray: this.formBuilder.array([
                this.formBuilder.group({
                    document: [null, Validators.required],
                    observation: [null, Validators.maxLength(this.observationMaxLength)],
                }),
            ]),
        });

        this.listAllMinibanks = await this.assignorService.getMinibanksByAssignor(this.assignorId);
    }

    get documentsArray() {
        return this.documentsForm.get("documentsArray") as FormArray;
    }

    addMoreDocuments() {
        const arrForm = {
            documentType: [null, Validators.required],
            document: [null, Validators.required],
            observation: [null],
        };

        this.documentsArray.push(this.formBuilder.group(arrForm));
    }

    removeDocument(i: number) {
        if (this.documentsArray.length === 1) {
            this.files = [];
            this.documentsForm.reset();
            return;
        }

        this.documentsArray.removeAt(i);
        this.files.splice(i, 1);
        this.updateFormValidation();
    }

    handleFileInput(event: Event) {
        const file = (event.target as HTMLInputElement).files[0];
        if (!this.fileValidator.validateSize([file])) return;       

        this.files.push(file);
        this.files.forEach((doc, i) => {
            if (doc === undefined) this.files.splice(i, 1);
        });
        this.updateFormValidation();
    }

    updateFormValidation() {
        this.documentsForm.updateValueAndValidity();
    }

    minibankAutocomplete() {

        this.filteredMinibanks$ = this.documentsForm.controls.minibank.valueChanges.pipe(
            startWith(""),
            map((minibank: string | IMinibankAssignorStatus) => {
                if (typeof minibank === "string") {
                    return minibank;
                }

                this.minibankToConnect = minibank;
                return minibank?.companyName;
            }),
            debounceTime(300),
            map((companyName) => {
                if (companyName) {
                    // eslint-disable-next-line unused-imports/no-unused-vars
                    return this.listAllMinibanks.filter((minibank) =>
                        minibank.companyName?.toLowerCase().includes(companyName.toLowerCase()),
                    );
                }
                return this.listAllMinibanks.slice();
            }),
        );
    }

    displayMinibankFn(minibank: IMinibankAssignorStatus) {
        if (!minibank) return "";
        return minibank?.fantasyName ? minibank.fantasyName : minibank.companyName;
    }

    cancel() {
        this.documentsForm.reset();
        this.files = [];
        this.willCloseAndEmitUpdate.emit(false);
    }

    async submit() {
        const formData = this.documentsForm.value;
        const additionalAnalysisCount = Number(formData.additionalAnalysisCount) ?? 0;
        const minibankId = formData.minibank.id;

        if (!minibankId) return this.snackBar.open("Selecione um Minibanco", "x", { duration: 2000 });

        if (this.documentsArray.length !== this.files.length) {
            this.snackBar.open("Alguns arquivos não foram selecionados corretamente.", "x", {
                duration: 2000,
            });
            return;
        }

        this.documentsForm.disable();
        this.isLoading = true;
        const filesToUpload: IDocumentUpload[] = [];

        formData.documentsArray.forEach((doc, index: number) => {
            filesToUpload.push({
                file: this.files[index],
                observation: doc.observation,
            });
        });

        const uploadPromises = filesToUpload.map(async ({ file, documentType, observation }) => {
            return await this.documentsUploadService.uploadFileAttachment({
                file,
                observation,
                documentType,
                minibankId,
                assignorId: this.assignorId,
            });
        });

        try {
            const res = await Promise.all(uploadPromises);

            const { body } = res[0];

            await this.assignorService.updateCreditAnalysis(
                this.assignorId,
                this.minibankToConnect.assignorStatusId,
                {
                    assignorId: this.assignorId,
                    minibankId,
                    status: CreditAnalysisStatusEnum.AGUARDANDO_APROVACAO,
                    attachmentId: body.attachment?.id,
                    additionalAnalysisCount,
                }
            );



            this.willCloseAndEmitUpdate.emit(true);
        } catch (error) {
            this.snackBar.open("Ocorreu um erro ao enviar os arquivos. Recarregue a página e tente novamente.", "x", {
                duration: 3500,
            });
            this.isLoading = false;
            this.documentsForm.enable();
        }
    }
}

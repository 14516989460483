import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";

import { SharedModule } from "app/shared/shared.module";
import { UserMenuComponent } from "./user.component";

@NgModule({
    declarations: [UserMenuComponent],
    imports: [MatButtonModule, MatDividerModule, MatIconModule, MatMenuModule, SharedModule],
    exports: [UserMenuComponent],
})
export class UserMenuModule {}

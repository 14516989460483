import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DocumentCreditAnalysisModalComponent } from "./document-credit-analysis-modal.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { DocumentCreditAnalysisModalService } from "./document-credit-analysis-modal.service";
import { StandardButtonModule } from "app/components/standard-button/standard-button.module";
import { FileValidatorModule } from "app/shared/services/file-validator/file-validator.module";

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        StandardButtonModule,
        FileValidatorModule,
    ],
    declarations: [DocumentCreditAnalysisModalComponent],
    exports: [DocumentCreditAnalysisModalComponent],
    providers: [ DocumentCreditAnalysisModalService ]
})
export class DocumentCreditAnalysisModalModule {}

import { Injectable } from "@angular/core";
import { ApiService } from "app/core/api/api.service";
import { IAssignorDocumentType } from "./interfaces/document-types.interface";

@Injectable({ providedIn: "root" })
export class DocumentCreditAnalysisModalService {
    constructor(
        private readonly http: ApiService
    ) {}

    getDocumentTypes(): Promise<IAssignorDocumentType[]> {
        return this.http.get("crm", "/assignor/document-types").toPromise();
    }

    uploadFile({
        assignorId,
        file,
        observation,
        minibankId,
    }: { file: File; assignorId: number; documentType: number; observation?: string; minibankId: number }) {
        return this.http
            .makeFileRequest("crm", `/cedente/upload/${assignorId}`, [{
                minibankId,
            }],
                [file], 
                observation,
            )
            .toPromise();
    }

    uploadFileAttachment({
        assignorId,
        file,
        observation,
        minibankId,
    }: { file: File; assignorId: number; documentType: number; observation?: string; minibankId: number }) {
        return this.http
            .makeFileRequest("crm", `/cedente/upload/${assignorId}/credit-analysis`, [{
                minibankId,
            }],
                [file], 
                observation,
            )
            .toPromise();
    }
}

/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { HttpHeaders } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ApolloLink, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { environment } from "environments/environment";

function getToken() {
    const token = localStorage.getItem("access_token");

    if (!token) return {};

    return {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}

function redirectSignInPage() {
    localStorage.clear();
    location.href = "/sign-in";
}

export function createApollo(httpLink: HttpLink) {
    const token = getToken();
    const auth = setContext(() => token);

    const errorHandler = onError(({ forward, operation, graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            const hasJWTError = graphQLErrors.some((error) => error.message === "jwt expired");

            if (hasJWTError) this.redirectSignInPage();
        }

        if (networkError) {
            if (networkError.message === "jwt expired") {
                this.redirectSignInPage();
            }
        }

        forward(operation);
    });

    const link = ApolloLink.from([
        errorHandler,
        auth,
        httpLink.create({ uri: `${environment.vars.apis.crm}/graphql` }),
    ]);

    const cache = new InMemoryCache({ addTypename: false });

    return {
        link,
        cache,
    };
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}

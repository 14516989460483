import { NgModule } from "@angular/core";
import { StandardButtonComponent } from "./standard-button.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [StandardButtonComponent],
    exports: [StandardButtonComponent],
})
export class StandardButtonModule {}

import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import ptBr from "@angular/common/locales/pt";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ExtraOptions, NoPreloading, RouterModule } from "@angular/router";
import { FuseModule } from "@fuse";
import { AppComponent } from "app/app.component";
import { appRoutes } from "app/app.routing";
import { CoreModule } from "app/core/core.module";
import { LayoutModule } from "app/layout/layout.module";
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics";
import { NgxHotjarModule, NgxHotjarRouterModule } from "ngx-hotjar";
import { NgxMaskModule } from "ngx-mask";
import { FuseConfigModule } from "../@fuse/services/config";
import { environment } from "../environments/environment";
import { DateLocaleModule } from "./configs/date-locale.module";
import { GraphQLModule } from "./configs/graphql.module";
import { appConfig } from "./core/config/app.config";
import { DocumentsUploadModule } from "./components/documents-upload/documents-upload.module";
import { DocumentCreditAnalysisModalModule } from "./modules/admin/cedente/components/document-credit-analysis-modal/document-credit-analysis-modal.module";
import * as amplitude from "@amplitude/analytics-browser";
import { CustomSnackbarModule } from "./components/v2/Snackbar/snackbar.module";

registerLocaleData(ptBr);

amplitude.track("View app-backoffice-module");

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: "enabled",
    preloadingStrategy: NoPreloading,
    relativeLinkResolution: "legacy",
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        FuseModule,
        CoreModule,
        LayoutModule,
        GraphQLModule,
        BrowserModule,
        HttpClientModule,
        DateLocaleModule,
        MatSnackBarModule,
        NgxHotjarRouterModule,
        BrowserAnimationsModule,
        NgxGoogleAnalyticsRouterModule,
        FuseConfigModule.forRoot(appConfig),
        NgxHotjarModule.forRoot(environment.vars.hj),
        RouterModule.forRoot(appRoutes, routerConfig),
        NgxGoogleAnalyticsModule.forRoot(environment.vars.ga),
        NgxMaskModule.forRoot(),
        DocumentsUploadModule,
        DocumentCreditAnalysisModalModule,
        CustomSnackbarModule,
    ],
    bootstrap: [AppComponent],
    providers: [],
})
export class AppModule {}

import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthService } from "app/core/auth/auth.service";
import { AuthUtils } from "app/core/auth/auth.utils";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService, private router: Router, private _snackBar: MatSnackBar) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler) {
        let newReq = req.clone();

        let isExternalReq = false;

        environment.vars.whitelist.map((item) => {
            if (newReq.url.includes(item)) {
                isExternalReq = true;
            }
        });

        const hasBearerTokenRequest =
            this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) && !isExternalReq;


        if (this._authService.accessToken && hasBearerTokenRequest) {
            newReq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + this._authService.accessToken),
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError(
                (error: {
                    error: {
                        message: string;
                    };
                    status: number;
                }) => {
                    const statusRedirect = [HttpStatusCode.Unauthorized];

                    if (
                        statusRedirect.includes(error.status as HttpStatusCode) ||
                        error.error?.message?.includes("Realize o login novamente")
                    ) {
                        this._authService.signOut();
                    }

                    if (error.error?.message === "Forbidden resource") {
                        this._snackBar.open("Você não tem permissão para acessar esta página", "OK");

                        // Prevent the router to loop back to the same page
                        if (this.router.url === "/") {
                            this.router.navigateByUrl("/sign-in");
                        }

                        this.router.navigate([".."]);
                    }

                    if (error.status === 403 && error.error.message === "Usuário inativo!") {
                        this.router.navigate([".."]);
                    }

                    return throwError(error);
                },
            ),
        );
    }
}